import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path2/src/components/layout.js";
import Weather from "../components/weather";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Hello,`}</h1>
    <p>{`I'm a Swiss front-end developer and designer based in `}<Weather mdxType="Weather" />{` Seattle.
Currently, I'm a front-end engineer at`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      